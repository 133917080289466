import React from "react"
import { Link } from "gatsby"
import MenuMainDesktop from "./menu-main-desktop"
import MenuLegal from "./menu-legal"
import MenuSocial from "./menu-social"
import { Box, Container, Flex, Themed } from "theme-ui"

const Footer = ( props ) => {

  const linksNavColor = props.linksnavcolor ? props.linksnavcolor : `white`

  return (
    <Box
      as={`footer`}
      sx={{
        backgroundColor: `black`,
        color: `white`,
        py: 6,
        "h3, h4, h5, li, a": {
          color: `white`,
        },
        section: {
          alignItems: [`flex-start`, `flex-end`],
          justifyContent: [`flex-start`, `space-between`],
          flexDirection: [`column`, `inherit`, `inherit`, `inherit`, `inherit`]
        },
        a: {
          borderBottom: 0,
          "&:hover": {
            borderBottom: 0,
          },
        },
        "nav.main-menu": {
          pt: 3,
          pb: 4,
        },
        "nav.social-menu": {
          pt: [4, 0, 0, 0, 0],
          pb: [2, 0, 0, 0, 0],
        },
        "nav.legal-menu": {
          pt: 4,
          pb: 2,
        },
        ".address > div:last-child": {
          marginLeft: 4,
        }
      }}
    >
      <Container as={`div`}>
        <MenuMainDesktop
          navlinkcolor={linksNavColor}
        />
        <Flex as={`section`}>
          <Box as={`div`}>
            <Themed.h4><Themed.a as={Link} to="/contact" rel="bookmark">Get in touch</Themed.a></Themed.h4>
          </Box>
          <Box as={`div`}>
            <MenuSocial />
          </Box>
        </Flex>
        <MenuLegal />
      </Container>
    </Box>
  )

}

export default Footer