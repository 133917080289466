import React from "react"
import { Flex, NavLink } from "theme-ui"

const MenuSocial = ( props ) => {

  return (
    <Flex
      as='nav'
      aria-label="Social Navigation"
      className="social-menu"
      sx={{
        "a[aria-current]": {
          color: 'secondary',
        },
        svg: {
          fill: `white`,
          height: [6, 6, 6, 6, 8],
        },
        a: {
          borderBottom: 0,
          color: `white`,
          "&:hover": {
            borderBottom: 0,
            color: `white`,
          },
        },
      }}
    >
      <NavLink href="https://twitter.com/nemeda2" rel="external nofollow">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
        <title>twitter</title>
        <path d="M34.632 12.492c0.016 0.324 0.022 0.652 0.022 0.976 0 9.98-7.594 21.484-21.48 21.484-4.266 0-8.232-1.25-11.574-3.394 0.592 0.070 1.192 0.106 1.8 0.106 3.54 0 6.794-1.208 9.376-3.23-3.302-0.062-6.092-2.242-7.052-5.242 0.46 0.086 0.934 0.132 1.42 0.132 0.69 0 1.358-0.090 1.99-0.262-3.454-0.696-6.056-3.746-6.056-7.406 0-0.032 0-0.062 0-0.094 1.018 0.566 2.184 0.906 3.42 0.946-2.026-1.356-3.36-3.664-3.36-6.286 0-1.382 0.372-2.68 1.024-3.796 3.722 4.57 9.288 7.574 15.562 7.89-0.128-0.554-0.194-1.128-0.194-1.722 0-4.168 3.378-7.546 7.548-7.546 2.172 0 4.134 0.914 5.512 2.382 1.718-0.34 3.334-0.968 4.794-1.832-0.564 1.762-1.762 3.242-3.32 4.176 1.528-0.184 2.98-0.586 4.336-1.188-1.012 1.516-2.292 2.844-3.768 3.906z"></path>
        </svg>
      </NavLink>
      <NavLink href="https://uk.linkedin.com/company/nemeda-io" rel="external nofollow">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
        <title>linkedin</title>
        <path d="M10 6c0 2.124-1.42 3.952-4.002 3.952-2.43 0-3.998-1.724-3.998-3.848 0-2.18 1.52-4.104 4-4.104s3.952 1.82 4 4zM2 38v-26h8v26h-8z"></path>
        <path d="M14 20.888c0-3.090-0.102-5.672-0.204-7.902h7.188l0.356 3.446h0.152c1.012-1.622 3.492-4 7.644-4 5.064 0 8.864 3.39 8.864 10.684v14.884h-8v-13.722c0-3.188-1.214-5.62-4-5.62-2.124 0-3.188 1.72-3.746 3.138-0.204 0.508-0.254 1.216-0.254 1.926v14.278h-8v-17.112z"></path>
        </svg>
      </NavLink>
    </Flex>
  )
}

export default MenuSocial