import React, { useState } from "react"
import { Link } from "gatsby"
import Logo from "./logo"
import MenuMain from "./menu-main"
import MenuMainDesktop from "./menu-main-desktop"
import { Box, Button, Container, Flex, Themed } from "theme-ui"

const Header = ( props ) => {

  const linksNavColor = props.linksnavcolor ? props.linksnavcolor : `white`

  const [isShown, setIsShown] = useState(false)

  /*
   * Esta es la funcion que controla todo
   * El estado de este componente se aplica a este componente
   * La función cambia el estado del componenten en setIsShown
   * La función ademas cambia el estado del componente padre con props.stateOnMainComponent
   * Conclusión: al hacer click se cambia el estado de estez componente y se cambia el estado del componente superior usando props.  
   */ 
  function toggle(e) {
    e.preventDefault()
    if(isShown === false ){
      setIsShown(true)
      props.stateOnMainComponent(false)
    }else {
      setIsShown(false)
      props.stateOnMainComponent(true)
    }
  }

  return(
    <Box
      as={`header`}
      sx={{
        bg: `transparent`,
        position: `absolute`,
        left: 0,
        top: 0,
        width: `100%`,
        zIndex: `99`,
        ".main-header": {
          alignItems: `center`,
          justifyContent: `space-between`,
          height: 24,
        },
        a: {
          borderBottom: 0,
          color: `white`,
          "&:hover": {
            borderBottom: 0,
            color: `white`,
          },
        },
        ".logo": {
          alignItems: `center`,
          color: `${linksNavColor}`,
          fontSize: 0,
          textTransform: `uppercase`,
          stroke: `${linksNavColor}`,
        },
        ".site-title": {
          ml: 2,
        },
        ".menu-button": {
          display: [`inherit`, `inherit`, `inherit`, `none`, `none`],
        },
        ".main-menu": {
          display: [`inherit`, `inherit`, `inherit`, `none`, `none`],
        },
        ".main-menu-desktop": {
          display: [`none`, `none`, `none`, `inherit`, `inherit`],
        },
      }}
    >
      <Container as="div">
        <Flex as="div" className="main-header">
          <Themed.a as={Link} to="/" aria-label="inicio"><Flex className={'logo'}><Logo /><span className="site-title">Nemeda</span></Flex></Themed.a>
          <MenuMainDesktop navlinkcolor={linksNavColor} />
          <Button
            title='Toggle Menu'
            className='menu-button'
            onClick={toggle}
            sx={{
              appearance: 'none',
              m: 0,
              p: 1,
              color: `${linksNavColor}`,
              bg: 'transparent',
              border: 0,
              ':focus': {
                outline: 0,
              },
              ':hover': {
                color: 'secondary',
              },
              svg: {
                display: 'block',
                margin: 0,
              }
            }}>
              {isShown === false
                ? <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width='24'
                    height='24'
                    fill="currentcolor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
                  </svg>
                : <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width='24'
                    height='24'
                    fill="currentcolor"
                    viewBox="0 0 40 40"
                  >
                    <path d="M28.696 29.698c-0.938 0.938-2.458 0.938-3.394 0l-5.302-6.060-5.302 6.058c-0.938 0.938-2.458 0.938-3.394 0-0.938-0.938-0.938-2.458 0-3.394l5.516-6.3-5.518-6.304c-0.938-0.938-0.938-2.456 0-3.394s2.456-0.938 3.394 0l5.304 6.062 5.302-6.062c0.938-0.938 2.456-0.938 3.394 0s0.938 2.458 0 3.394l-5.516 6.304 5.516 6.3c0.938 0.938 0.938 2.458 0 3.396z"></path>
                  </svg>
              }
          </Button>
          {isShown &&
            <MenuMain navlinkcolor={linksNavColor} />
          }
        </Flex>
      </Container>
    </Box>
  )

}

export default Header