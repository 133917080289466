import React from "react"
import MenuSocial from "./menu-social"
import { Link } from "gatsby"
import { Box, Container, Flex, NavLink } from "theme-ui"
import { darken } from '@theme-ui/color'

const MenuMain = ( props ) => {

  const navLinkColor = props.navlinkcolor

  return (
    <Box
      as='div'
      className="main-menu"
      sx={{
        backgroundImage: t => `linear-gradient(to top, black, ${darken('primary', 0.3)(t)})`,
        height: `calc(100vh - 6rem)`,
        left: 0,
        position: `absolute`,
        top: `6rem`,
        width: `100vw`,
        zIndex: `100`,
        "a[aria-current]": {
          color: 'secondary',
        },
        nav: {
          justifyContent: `flex-end`,
        },
        a: {
          borderBottom: 0,
          color: `white`,
          display: `block`,
          fontSize: 4,
          mb: 2,
          textAlign: `right`,
          "&:hover": {
            borderBottom: 0,
            color: `${navLinkColor}`,
          },
        },
        ".menu-items": {
          flexDirection: `column`,
          pt: 4,
        },
      }}
    >
      <Container as="div">
        <Flex
          as="nav"
          aria-label="Primary Navigation"
          className="menu-items"
        >
          <NavLink as={Link} to="/about" rel="bookmark">About</NavLink>
          <NavLink as={Link} to="/services" rel="bookmark">Services</NavLink>
          <NavLink as={Link} to="/insights" rel="bookmark">Insights</NavLink>
          <NavLink as={Link} to="/contact" rel="bookmark">Contact</NavLink>
        </Flex>
        <MenuSocial />
      </Container>
    </Box>
  )
}

export default MenuMain