import React from "react"
import { Link } from "gatsby"
import { Flex, NavLink } from "theme-ui"

const MenuLegal = () => {

  return(
    <Flex
      as='nav'
      aria-label="Secondary Navigation"
      className="legal-menu"
      sx={{
        a: {
          borderBottom: 0,
          color: `white`,
          "&:hover": {
            borderBottom: 0,
            color: `white`,
          },
        },
      }}
    >
      <NavLink as={Link} to="/privacy-policy">Privacy policy</NavLink>
    </Flex>
  )

}

export default MenuLegal