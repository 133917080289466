import React from "react"
import { Link } from "gatsby"
import { Flex, NavLink } from "theme-ui"
// import MenuMain from "./menu-main"

const MenuMainDesktop = ( props ) => {

  const navLinkColor = props.navlinkcolor

  return (
    <Flex
      as='nav'
      aria-label="Primary Navigation"
      className="main-menu-desktop"
      sx={{
        "a[aria-current]": {
          color: 'secondary',
        },
        a: {
          borderBottom: 0,
          color: `${navLinkColor}`,
          "&:hover": {
            borderBottom: 0,
            color: `${navLinkColor}`,
          },
        },
      }}
    >
      <NavLink as={Link} to="/about" rel="bookmark">About</NavLink>
      <NavLink as={Link} to="/services" rel="bookmark">Services</NavLink>
      <NavLink as={Link} to="/insights" rel="bookmark">Insights</NavLink>
      <NavLink as={Link} to="/contact" rel="bookmark">Contact</NavLink>
    </Flex>
  )

}

export default MenuMainDesktop