import React from "react"

const Logo = () => {

	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 70 70">
			<polyline fill="none" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			7.789,51.302 16.004,28.733 55.154,42.983 63.43,20.247 	"/>
			<polyline fill="none" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			2.835,36.138 10.083,16.224 49.518,30.576 56.265,12.038 	"/>
			<polyline fill="none" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			68.031,35.274 60.743,55.299 21.986,41.193 15.341,59.451 	"/>
			<polyline fill="none" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			17.392,8.559 44.015,18.248 48.134,6.931 	"/>
			<polyline fill="none" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			23.953,64.235 27.893,53.411 52.954,62.517 	"/>
			<line fill="none" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="30.884" y1="3.364" x2="38.325" y2="6.072"/>
			<line fill="none" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="34.155" y1="65.405" x2="40.036" y2="67.503"/>
		</svg>
	)

}

export default Logo
